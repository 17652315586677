<template>
  <b-container fluid class="bg full-height">
    <b-row class="full-height">

      <b-col cols="12" md="5" class="left-side"> </b-col>

      <b-col
        cols="12"
        md="7"
        class="right-side px-3 d-flex justify-content-center align-item-center"
      >
        <div class="inner-wrapper" data-anima="bottom">
          <span class="logo mb-5">
            <img src="@/assets/img/logo.svg" />
          </span>

          <div class="voltar-login">
            <router-link to="/"
              ><img src="~@/assets/img/icons/long-arrow.svg" />
              {{ $t("views.seller.register.text_1432") }}</router-link
            >
          </div>

          <div class="info-recuperacao" v-if="!token">
              <h4 class="title">{{ this.sent ? $t("views.seller.password_recovery.text_1271"): $t("views.seller.password_recovery.text_1270") }}</h4>
              <p class="sub-title">{{ this.sent ? $t("views.seller.password_recovery.text_1272") : $t("views.seller.password_recovery.text_1256") }}</p>
          </div>
          
          <div class="info-recuperacao" v-if="token">
            <h5>{{ $t('views.seller.password_recovery.text_1257') }}</h5>
            <p>
              {{ $t('views.seller.password_recovery.text_1258') }}
            </p>
          </div>

          <b-form novalidate v-if="!sent" @submit.prevent="onSubmit">
            <b-form-group v-if="!token" :label="$t('views.seller.password_recovery.text_1471')" label-for="email">
              <b-form-input
                id="email"
                :placeholder="$t('views.seller.password_recovery.text_1472')"
                v-model="email"
                type="email"
                name="email"
                v-validate="'required|email'"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('email') : null"
              >
                {{ $t('views.seller.password_recovery.text_1259') }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group v-if="token" :label="$t('views.seller.password_recovery.text_1473')" label-for="password">
              <b-form-input
                id="password"
                placeholder="••••••••"
                v-model="password"
                type="password"
                name="password"
                v-validate="'required|min:8'"
                ref="password"
              ></b-form-input>
              <span
                class="mostrar-senha"
                ref="mostrar_senha"
                @click="mostrarSenha"
                >{{ $t('views.seller.password_recovery.text_1474') }}</span
              >
              <b-form-invalid-feedback
                :state="submit ? !errors.has('password') : null"
              >
                {{ $t('views.seller.password_recovery.text_1260') }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              v-if="token"
              :label="$t('views.seller.password_recovery.text_1475')"
              label-for="confirm_password"
            >
              <b-form-input
                id="confirm_password"
                placeholder="••••••••"
                v-model="confirm_password"
                type="password"
                name="confirm_password"
                v-validate="'required|min:8|confirmed:password'"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('confirm_password') : null"
              >
                {{ $t('views.seller.password_recovery.text_1261') }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-row class="mt-4">
              <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="dark"
                class="d-inline-block col-12"
              >
                <BaseButton
                  variant="secondary"
                  v-if="!token"
                  ref="button"
                  :disabled="loading"
                  type="submit"
                  class="btn-submit"
                  @click="onSubmit"
                >
                  {{ $t('views.seller.password_recovery.text_1262') }}
                </BaseButton>

                <Captcha
                  ref="Captcha"
                  v-if="token"
                  @changeVerify="changeVerify"
                >
                  <BaseButton
                    variant="secondary_outline"
                    v-if="token"
                    ref="button"
                    :disabled="loading"
                    type="submit"
                    class="btn-submit"
                    @click="onSubmit"
                  >
                    {{ $t('views.seller.password_recovery.text_1263') }}
                  </BaseButton>
                </Captcha>
              </b-overlay>
            </b-row>
          </b-form>

          <BaseButton
            variant="secondary_outline"
            v-if="sent"
            class="btn-submit mt-5"
            @click="returnLogin"
          >
            {{ $t('views.seller.password_recovery.text_1264') }}
          </BaseButton>
        </div>
        <div class="mt-5 termos-privacidade">
              <a
                class="links-help mr-1"
                 href="https://www.voompcreators.com.br/termos-e-documentacoes/"
                target="_blank"
                >{{ $t("views.seller.login.text_1568") }}</a
              >
              <span class="links-help mr-1">|</span>
              <a
                class="links-help"
                href="https://www.canaldatransparencia.com.br/cogna/"
                target="_blank"
                >{{ $t("views.seller.login.text_1566") }}</a
              >
        </div>
        <div
          v-if="loading"
          class="py-4 d-flex justify-content-center align-items-center"
        >
          <b-spinner variant="dark" label="Spinning"></b-spinner>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import PasswordRecoveryService from "@/services/resources/PasswordRecoveryService";

const service = PasswordRecoveryService.build();

import Captcha from "@/components/security/Captcha.vue";

export default {
  name: "Login",
  components: {
    Captcha,
  },
  data() {
    return {
      submit: false,
      email: "",
      password: "",
      confirm_password: "",
      success: true,
      loading: false,
      token: false,
      sent: false,
      captcha: "",
    };
  },
  methods: {
    changeVerify(e) {
      this.captcha = e;
      this.onSubmit("get");
    },
    returnLogin() {
      this.$router.push("/");
    },
    mostrarSenha() {
      const input = document.querySelector("#password");
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        input.setAttribute("type", "text");
        this.$refs.mostrar_senha.innerText = this.$t('views.seller.password_recovery.text_1476');
      } else {
        input.setAttribute("type", "password");
        this.$refs.mostrar_senha.innerText = this.$t('views.seller.password_recovery.text_1474');
      }
    },

    onSubmit(get) {
      if (get !== "get" && this.token) {
        this.$refs.Captcha.setCaptcha();
        return;
      }
      this.submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true;

          if (this.token) {
            this.changePassword();
          } else {
            this.requestToken();
          }
        }
      });
    },
    requestToken() {
      let data = {
        email: this.email,
        type: "REQUEST_TOKEN",
      };

      service
        .create(data)
        .then(() => {
          this.$bvToast.toast(
            this.$t('views.seller.password_recovery.text_1265'),
            {
              title: this.$t('views.seller.password_recovery.text_1266'),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );

          this.sent = true;
          this.$refs.titulo_recupera.innerText = `✔️ ${this.$t('views.seller.password_recovery.text_1267')}`;
          this.$refs.texto_recupera.classList.add("sent");
          this.$refs.texto_recupera.innerHTML = `${this.$t('views.seller.password_recovery.text_1268')} <b>${this.email}</b>`;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changePassword() {
      let data = {
        password: this.password,
        token: this.token,
        captcha: this.captcha,
        type: "CHANGE_PASSWORD",
      };

      service
        .create(data)
        .then(() => {
          this.$bvToast.toast(this.$t('views.seller.password_recovery.text_1269'), {
            title: this.$t('views.seller.password_recovery.text_1266'),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    if (typeof this.$route.params.token != "undefined") {
      this.token = this.$route.params.token;
    }
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background: rgba(0, 33, 99, 0.1);
}

.left-side {
  background-image: url("~@/assets/img/recuperar-senha-image.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #fff;
}

.right-side {
  background: #fff;
  padding: 0 !important;
  height: 100%;

  .inner-wrapper {
    margin-top: 90px;
    // width: 60%;
    padding: 1% 18%;

    .logo {
      img {
        width: 143.83px;
        margin-bottom: 40px;
      }
    }
  }
}

.button-secondary_outline{
  width: auto;
  color: #002e74;
  border: 1px solid #002e74!important;
  background: #ffffff!important;
  border: 1px solid #002e74;
  cursor: pointer;
}

.voltar-login {
  margin-bottom: 20px;
}
.voltar-login a {
  display: flex;
  align-items: center;
  color: #333;
  font-size: 12px;
  font-weight: bold;
  max-width: 80px;
}
.voltar-login img {
  margin-right: 8px;
  width: 16px;
  font-weight: bold;
}
.info-recuperacao h5 {
  font-size: 16px;
  font-weight: 600;
}
.info-recuperacao p {
  color: #81858e;
  font-size: 14px;
  line-height: 1.5;
  margin: 8px 0 30px 0;
}
.info-recuperacao p.sent {
  font-size: 15px;
}
.btn-submit {
  width: 50%;
  background-color: #0a1e3e;
  border:0;
  height: 49px;
  font-size: 10pt;
  width: 100%;
  margin-top: 15px;
}
.form-group {
  position: relative;
}
.form-group + .form-group {
  margin-top: 25px;
}

.termos-privacidade a{
    font-size: 14px;
    color: #333;
}
.termos-privacidade{
    bottom: 15px;
    position: absolute;
    text-align: center;
}
.mostrar-senha {
  font-size: 13px;
  position: absolute;
  top: 55%;
  right: 20px;
  cursor: pointer;
  color: #81858e;
}

.title{
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
}
.sub-title{
    margin-top: 15px;
    margin-bottom: 45px;
    font-size: 14px;
}

input[type="text"], input[type="password"], input[type="email"]{
  height: 53px!important;
  padding: 16px 24px 16px 24px!important;
  border-radius: 4px!important;
  border: 1px solid #DEDEDE!important;
  
  font-family: 'Montserrat', sans-serif!important;
  font-size: 14px!important;
  font-weight: 400!important;
  line-height: 21px!important;
  letter-spacing: 0em!important;
  text-align: left!important;

}

label{
  font-weight: 600!important;
  margin-bottom: 4px !important;
  font-size: 16px!important;
  line-height: 24px!important;
}

input[type="text"], input[type="password"], input[type="email"]{
  height: 53px!important;
  padding: 16px 24px 16px 24px!important;
  border-radius: 4px!important;
  border: 1px solid #DEDEDE!important;
  
  font-family: 'Montserrat', sans-serif!important;
  font-size: 14px!important;
  font-weight: 400!important;
  line-height: 21px!important;
  letter-spacing: 0em!important;
  text-align: left!important;

}

.logo img {
    width: 138.83px !important;
  }

@media screen and (max-width: 768px) {
  .logo img {
    width: 80px !important;
    margin-bottom: 30px !important;
  }
  .btn-submit {
    margin-bottom: 40px;
  }
}
</style>
